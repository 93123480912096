import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';


const hdrTextureURL = new URL('../img/kitchen.hdr', import.meta.url);
const entireUrl = new URL('../assets/newUteruscomplete.glb', import.meta.url);

const renderer = new THREE.WebGLRenderer({antialias: true});
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);


const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
    );
    // Sets the color of the background
    // renderer.setClearColor(0xFEFEFE);
    scene.background = new THREE.Color( 0x2C2020);
// Sets orbit control to move the camera around
const orbit = new OrbitControls(camera, renderer.domElement);

// Camera positioning
camera.position.set(6, 8, 14);
orbit.update();

const loadingManager = new THREE.LoadingManager();

renderer.outputColorSpace = THREE.SRGBColorSpace;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1.8;

//setting upp HDRI 
const loader = new RGBELoader();
loader.load(hdrTextureURL, function(texture){
    texture.mapping = THREE.EquirectangularReflectionMapping;
    // scene.background = texture; //comment out this line to make the HDRI invisible
    scene.environment = texture;
});

const assetLoader = new GLTFLoader(loadingManager);
// loadingManager.onStart = function(url, item, total){
    //     console.log(`Started loading: ${url}`);
    // };
    const progressBar = document.getElementById('progress-bar');
    loadingManager.onProgress = function(url, loaded, total){
        progressBar.value = (loaded / total) * 100;
    };
    const progressBarContainer = document.querySelector('.progress-bar-container')
    loadingManager.onLoad = function(){
        progressBarContainer.style.display = 'none';
    };
    
    assetLoader.load(entireUrl.href, function(gltf){
        const model = gltf.scene;
        scene.add(model);
        model.position.set(5, 0, -7);
    }, undefined, function(error){
        console.error(error);
    })
    function animate() {
        renderer.render(scene, camera);
    }
renderer.setAnimationLoop(animate);

window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
});